import React from "react"
import Layout from "../components/layout"
import WorkCard from "../components/workCard"
import SEO from "../components/seo"

const WorkPage = ({ pageContext: { workData } }) => (
  <Layout
    headerImage={(
      <div style={{ backgroundColor: "#4B7E89" }}>
        <div class="pt-4 pb-4">
          <h1 class="h1-text text-center mb-0">My Work</h1>
        </div>
      </div>
    )}
  >
    <SEO title="Work" />
    <div className="mt-5 d-flex flex-row flex-wrap justify-content-between">
      {workData.map(item => (
        <WorkCard cardData={item} />
      ))}
    </div>
  </Layout>
)

export default WorkPage
