import React from "react"
import { navigate } from "gatsby"
import { Card } from 'react-bootstrap'
import "./workCard.css"

const WorkCard = ({ cardData }) => {
  return (
    <Card
      onClick={() => navigate(`/work/${cardData.link}`)}
    >
      <Card.Img 
        variant="top" 
        src={require(`../images/${cardData.cardImage}`)}
      />
      <Card.Body>
        <Card.Title>{cardData.title}</Card.Title>
        <Card.Text>{cardData.shortDescription}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default WorkCard